import { Container, Grid, makeStyles } from "@material-ui/core";
import { Subtitle } from "components";
import { LOYALTY_PROGRAM_GROUPS } from "constants/groups";
import { useAuth } from "hooks/useAuth";
import { useFeaturedGroups } from "../hooks/useFeaturedGroups";
import ExclusiveOffer from "./ExclusiveOffer";

const useStyles = makeStyles({
  root: {
    marginBottom: "40px",
  },
});

const ExclusiveOfferList = () => {
  const classes = useStyles();
  const { session } = useAuth();
  const featuredGroups = useFeaturedGroups();
  const shouldDisplayExclusive = !!featuredGroups?.data;
  const userIsInLoyaltyGroup = featuredGroups?.data?.group?.buckets?.some(
    ({ key }) => LOYALTY_PROGRAM_GROUPS.includes(key)
  );

  return (
    <>
      {shouldDisplayExclusive ? (
        <Container className={classes.root}>
          <Subtitle>Ofertas Exclusivas </Subtitle>
          <Grid container direction="row" alignItems="center" spacing={2}>
            {userIsInLoyaltyGroup && (
              <Grid item md={3}>
                <ExclusiveOffer
                  name={LOYALTY_PROGRAM_GROUPS.join("&grupo=")}
                  label="+Fidelidade"
                />
              </Grid>
            )}

            {featuredGroups.data.group.buckets.map(({ key }) => {
              if (LOYALTY_PROGRAM_GROUPS.includes(key)) {
                return null;
              }

              return session?.groups?.some(({ label }) => label === key) ? (
                <Grid item md={3} key={key}>
                  <ExclusiveOffer name={key} />
                </Grid>
              ) : null;
            })}
          </Grid>
        </Container>
      ) : null}
    </>
  );
};

export default ExclusiveOfferList;
