import React from "react";
import Link from "next/link";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Button, Container, Grid, makeStyles } from "@material-ui/core";
import VehicleItemCard from "components/Vehicle/ItemCard/VehicleItemCard";
import { useFeaturedVehicles } from "../hooks/useFeaturedVehicles";
import { Subtitle } from "components";
import { useVehicleWebsocket } from "hooks/useVehicleWebsocket";
import { useAuth } from "hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "40px 0px 110px 0px",
    position: "relative",
    marginBottom: "64px",
  },
  button: {
    bottom: "-30px",
    display: "flex",
    left: "50%",
    position: "absolute",
    transform: "translateX(-50%)",
  },
  "@media (max-width: 1024px)": {
    button: {
      width: "322px !important",
    },
    root: {
      padding: "40px 0px 88px 0px",
      "& h2": {
        fontSize: "24px",
        lineHeight: "32px",
      },
    },
  },
}));

const FeaturedList = () => {
  const classes = useStyles();
  const mobile = useMediaQuery("(max-width: 599px)");
  const featuredVehicles = useFeaturedVehicles();
  const websocketData = useVehicleWebsocket();
  const { isAuthenticated } = useAuth();

  if (
    featuredVehicles.isLoading ||
    featuredVehicles.isError ||
    featuredVehicles.isIdle ||
    featuredVehicles.data.length === 0
  ) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Container>
        <Subtitle>Encontre aqui {mobile ? <br /> : null}o seu veículo</Subtitle>
        <Grid container spacing={2} justifyContent="space-around">
          {featuredVehicles.data.map((vehicle) => (
            <Grid key={vehicle._source.sku} item xs={12} sm={6} md={3}>
              <VehicleItemCard
                vehicle={vehicle._source}
                websocketData={websocketData}
                isUserAuthenticated={isAuthenticated}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Link href="/catalogo" passHref>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          size="large"
        >
          Ver mais carros
        </Button>
      </Link>
    </div>
  );
};

export default FeaturedList;
